<template>
  <div>
    <Header ref="header"/>

    <div class="homeindex">
      <div class="content" style="min-height: 900px;">
        <div style="height: 30px"></div>



        <div class="visual-item w1200" style="display: block;">

          <div class="view" >
            <!--            <el-alert-->
            <!--                title="本页面促销活动仅为公司促销的一小部分品种，部分品种存在限销，部分品种促销只针对指定客户，详细政策请看品种详情页，以公司实际开票为准。"-->
            <!--                type="success"-->
            <!--                effect="dark" style="margin-bottom: 20px">-->
            <!--            </el-alert>-->

            <!--            <el-alert-->
            <!--                title="本页面促销活动仅为公司促销的一小部分品种，部分品种存在限销，部分品种促销只针对指定客户，详细政策请看品种详情页，以公司实际开票为准。"-->
            <!--                type="info"-->
            <!--                effect="dark" style="margin-bottom: 20px">-->
            <!--            </el-alert>-->

            <!--            <el-alert-->
            <!--                title="本页面促销活动仅为公司促销的一小部分品种，部分品种存在限销，部分品种促销只针对指定客户，详细政策请看品种详情页，以公司实际开票为准。"-->
            <!--                type="warning"-->
            <!--                effect="dark" style="margin-bottom: 20px">-->
            <!--            </el-alert>-->

            <!--            <el-alert-->
            <!--                title="本页面促销活动仅为公司促销的一小部分品种，部分品种存在限销，部分品种促销只针对指定客户，详细政策请看品种详情页，以公司实际开票为准。"-->
            <!--                type="error"-->
            <!--                effect="dark" style="margin-bottom: 20px" >-->
            <!--            </el-alert>-->
          </div>


          <div class="view">
            <div class="store-channel">
              <div class="rec-store-list">
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[0].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[0].url"></div>
                  </router-link>
                </div>
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[1].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[1].url"></div>
                  </router-link>
                </div>
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[2].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[2].url"></div>
                  </router-link>
                </div>

              </div>
            </div>
            <div class="store-channel">
              <div class="rec-store-list">
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[3].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[3].url"></div>
                  </router-link>
                </div>
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[4].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[4].url"></div>
                  </router-link>
                </div>
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[5].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[5].url"></div>
                  </router-link>
                </div>
              </div>
            </div>

            <div class="store-channel">
              <div class="rec-store-list">
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[6].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[6].url"></div>
                  </router-link>
                </div>

                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[7].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[7].url"></div>
                  </router-link>
                </div>

                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[8].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[8].url"></div>
                  </router-link>
                </div>
              </div>
            </div>

            <div class="store-channel">
              <div class="rec-store-list">
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[9].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[9].url"></div>
                  </router-link>
                </div>

                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[10].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[10].url"></div>
                  </router-link>
                </div>

                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[11].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[11].url"></div>
                  </router-link>
                </div>
              </div>
            </div>

            <div class="store-channel">
              <div class="rec-store-list">
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[12].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[12].url"></div>
                  </router-link>
                </div>

                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[13].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[13].url"></div>
                  </router-link>
                </div>

                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[14].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[14].url"></div>
                  </router-link>
                </div>
              </div>
            </div>

            <div class="store-channel">
              <div class="rec-store-list">
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[15].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[15].url"></div>
                  </router-link>
                </div>

                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[16].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[16].url"></div>
                  </router-link>
                </div>

                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[17].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[17].url"></div>
                  </router-link>
                </div>
              </div>
            </div>

            <div class="store-channel">
              <div class="rec-store-list">
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[18].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[18].url"></div>
                  </router-link>
                </div>

                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[19].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[19].url"></div>
                  </router-link>
                </div>

                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[20].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[20].url"></div>
                  </router-link>
                </div>

              </div>
            </div>

            <div class="store-channel">
              <div class="rec-store-list">
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[21].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[21].url"></div>
                  </router-link>
                </div>

                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[22].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[22].url"></div>
                  </router-link>
                </div>

                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[23].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[23].url"></div>
                  </router-link>
                </div>

              </div>
            </div>

            <div class="store-channel">
              <div class="rec-store-list">
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[24].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[24].url"></div>
                  </router-link>
                </div>

                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[25].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[25].url"></div>
                  </router-link>
                </div>

                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[26].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[26].url"></div>
                  </router-link>
                </div>

              </div>
            </div>

            <div class="store-channel">
              <div class="rec-store-list">
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[27].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[27].url"></div>
                  </router-link>
                </div>

                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[28].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[28].url"></div>
                  </router-link>
                </div>

                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[29].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[29].url"></div>
                  </router-link>
                </div>

              </div>
            </div>

            <div class="store-channel">
              <div class="rec-store-list">
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[30].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[30].url"></div>
                  </router-link>
                </div>

                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[31].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[31].url"></div>
                  </router-link>
                </div>

                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[32].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[32].url"></div>
                  </router-link>
                </div>

              </div>
            </div>
          </div>

        </div>
      </div>
    </div>

    <Footer/>
  </div>
</template>

<script>
import Header from '@/components/header/Index'
import Footer from '@/components/footer/Index'
export default {
  name: "liushen",
  components: {
    Header,
    Footer
  },
  mounted() {
    this.$refs.header.hideCate();
  },
  data() {
    return {
      Floor:[
        {'index':0,'url':'http://image.yabyy.com/home/jxy/7000000.png','id':'7000000','search':''},
        {'index':1,'url':'http://image.yabyy.com/home/jxy/7000002.png','id':'7000002','search':''},
        {'index':2,'url':'http://image.yabyy.com/home/jxy/7000003.png','id':'7000003','search':''},
        {'index':3,'url':'http://image.yabyy.com/home/jxy/7000004.png','id':'7000004','search':''},
        {'index':4,'url':'http://image.yabyy.com/home/jxy/7000005.png','id':'7000005','search':''},
        {'index':5,'url':'http://image.yabyy.com/home/jxy/7000007.png','id':'7000007','search':''},
        {'index':6,'url':'http://image.yabyy.com/home/jxy/7000008.png','id':'7000008','search':''},
        {'index':7,'url':'http://image.yabyy.com/home/jxy/7000006.png','id':'7000006','search':''},
        {'index':8,'url':'http://image.yabyy.com/home/jxy/7000009.png','id':'7000009','search':''},
        {'index':9,'url':'http://image.yabyy.com/home/jxy/7000012.png','id':'7000012','search':''},
        {'index':10,'url':'http://image.yabyy.com/home/jxy/7000011.png','id':'7000011','search':''},

        {'index':11,'url':'http://image.yabyy.com/home/jxy/7000013.png','id':'7000013','search':''},
        {'index':12,'url':'http://image.yabyy.com/home/jxy/7000014.png','id':'7000014','search':''},
        {'index':13,'url':'http://image.yabyy.com/home/jxy/7000015.png','id':'7000015','search':''},
        {'index':14,'url':'http://image.yabyy.com/home/jxy/7000016.png','id':'7000016','search':''},
        {'index':15,'url':'http://image.yabyy.com/home/jxy/7000017.png','id':'7000017','search':''},
        {'index':16,'url':'http://image.yabyy.com/home/jxy/7000021.png','id':'7000021','search':''},
        {'index':17,'url':'http://image.yabyy.com/home/jxy/7000022.png','id':'7000022','search':''},
        {'index':18,'url':'http://image.yabyy.com/home/jxy/7000023.png','id':'7000023','search':''},
        {'index':19,'url':'http://image.yabyy.com/home/jxy/7000018.png','id':'7000018','search':''},
        {'index':20,'url':'http://image.yabyy.com/home/jxy/7000019.png','id':'7000019','search':''},

        {'index':21,'url':'http://image.yabyy.com/home/jxy/7000020.png','id':'7000020','search':''},
        {'index':22,'url':'http://image.yabyy.com/home/jxy/7000024.png','id':'7000024','search':''},
        {'index':23,'url':'http://image.yabyy.com/home/jxy/7000025.png','id':'7000025','search':''},
        {'index':24,'url':'http://image.yabyy.com/home/jxy/7000026.png','id':'7000026','search':''},
        {'index':25,'url':'http://image.yabyy.com/home/jxy/7000027.png','id':'7000027','search':''},
        {'index':26,'url':'http://image.yabyy.com/home/jxy/7000028.png','id':'7000028','search':''},
        {'index':27,'url':'http://image.yabyy.com/home/jxy/7000029.png','id':'7000029','search':''},
        {'index':28,'url':'http://image.yabyy.com/home/jxy/7000030.png','id':'7000030','search':''},
        {'index':29,'url':'http://image.yabyy.com/home/jxy/7000031.png','id':'7000031','search':''},
        {'index':30,'url':'http://image.yabyy.com/home/jxy/7000032.png','id':'7000032','search':''},

        {'index':31,'url':'http://image.yabyy.com/home/jxy/7000033.png','id':'7000033','search':''},
        {'index':32,'url':'http://image.yabyy.com/home/jxy/7000034.png','id':'7000034','search':''},
      ]
    }
  }
}
</script>

<style scoped>
.rec-store-item{
  width: 390px;
}
.rec-store-item .p-img {
  width: 390px;
  height: 380px;
}
</style>
